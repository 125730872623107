.main {
  &__about {
    margin: 0 20px 0 20px;
    z-index: 0;
    color: $color-light;
    font-family: $font-main;
    font-size: rem(20);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    @include media($tablet) {
      min-height: 100vh;
    }

    &__title {
      margin-top: 35px;
      font-size: rem(70);
      line-height: 90%;
      letter-spacing: 0.03em;
      font-family: $font-titles;

      @include media($tablet) {
        font-size: rem(120);
      }

      & span {
        display: block;
        line-height: 90%;
      }
    }
    &__introduction {
      text-align: left;
      font-family: $font-secondary;
      font-size: rem(25);
      padding-bottom: 30px;
      line-height: 1.3em;
      left: 50%;

      @include media($tablet) {
        margin: 0 40px 0 40px;
        font-family: $font-main;
        font-size: rem(42);
        text-align: left;
      }
    }
  }

  &__subtitle {
    text-align: center;
    color: $color-light;
    font-family: $font-titles;
    font-size: rem(50);
    text-transform: uppercase;
    margin: 0;
    @include media($tablet) {
      font-size: rem(75);
    }
  }
}

.wrapper {
  max-width: 1000px;
  margin: 0 auto;
}
