.main__skills {
  color: $color-light;
  font-family: $font-main;
  text-align: center;

  @include media($tablet) {
    text-align: right;
    margin-right: 40px;
  }

  &__title {
    font-family: $font-titles;
    font-size: rem(50);
    text-transform: uppercase;
    margin-left: 20px;

    @include media($tablet) {
      font-size: rem(75);
    }
  }

  &__list {
    font-size: rem(26);
    @include media($tablet) {
      text-align: right;
      font-size: rem(35);
    }

    &__item {
      height: 50px;
      margin: 0 20px 0 20px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;

      @include media($tablet) {
        text-align: right;
        flex-direction: row;
        justify-content: flex-end;
      }

      &__text {
        flex-shrink: 10;
      }

      &__smiley {
        width: 30px;
        margin: 0 10px 0 10px;

        & img {
          width: 100%;
        }
      }
    }
  }
}
