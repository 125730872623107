.main__stuff {
  text-align: center;
  color: $color-light;
  font-family: $font-main;
  font-size: rem(25);
  margin: 0 15px 0 15px;

  &__title {
    font-family: $font-titles;
    font-size: rem(50);
    text-transform: uppercase;

    @include media($tablet) {
      font-size: rem(65);
    }
  }

  &__phrase {
    height: 90px;
    display: flex;
    align-content: center;
    justify-content: center;

    & span {
      white-space: pre-wrap;

      @include media($tablet) {
        white-space: nowrap;
      }
    }

    &__icon {
      flex-shrink: 0;
      width: 30px;
      margin: 0 10px 0 10px;

      & img {
        width: 100%;
      }
    }
    &__link {
      display: table;
      margin: 0 auto;
    }
  }
}
