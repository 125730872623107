@font-face {
  font-family: 'Mirage Black';
  src: url('./fonts/Mirage-Black.otf') format('truetype');
}

@font-face {
  font-family: 'Mirage Bold';
  src: url('./fonts/Mirage-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'Mirage Regular';
  src: url('./fonts/Mirage-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'Mirage Thin';
  src: url('./fonts/Mirage-Thin.otf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

@import 'reset/reset';

/* core */
@import 'core/variables';
@import 'core/mixins';
@import 'core/functions';

@import 'design/links';
@import 'design/main';
@import 'design/background';
@import 'design/social';
@import 'design/projectList';
@import 'design/header';
@import 'design/skills';
@import 'design/stuff';
@import 'design/projectDetail';
@import 'design/error';
@import 'design/about';
@import 'design/creativity';
