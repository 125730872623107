.social {
  font-family: $font-main;
  color: $color-light;
  font-size: rem(30);
  text-align: center;
  margin: 40px auto;

  & p {
    margin-bottom: 10px;
    font-size: rem(24);
  }

  &__email {
    font-size: rem(30);
  }

  &__icons {
    margin-top: 20px;

    &__icon {
      margin-right: 10px;
      position: relative;
      top: 0;
      transition: top ease 0.5s;
      &:hover {
        top: -10px;
      }
    }
  }
}
