.background-change {
  background-color: $color-light;
}

.home-icon {
  color: $color-light;
  position: absolute;
  left: 30px;
  top: 30px;
  font-size: rem(25);
}

.main__project {
  font-family: $font-projects;
  color: $color-medium;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: rem(16);
  text-align: center;

  @include media($tablet) {
    font-size: rem(20);
  }

  & h3,
  h4 {
    text-align: center;
    padding: 0 10px 10px 10px;
    font-family: $font-titles;
    font-size: rem(40);
    background: -webkit-linear-gradient(#fcdf1a, #fe950d, #f84770);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include media($tablet) {
      font-size: rem(50);
      margin-right: 25px;
    }
  }

  & p {
    padding: 0 10px 0 10px;
  }

  &__info {
    text-align: center;
  }

  &__tags {
    font-family: $font-main;
    color: $color-medium;
    text-align: center;
    font-size: rem(24);
    margin-left: 15%;
    margin-bottom: 25px;
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 15px 15px 0 0;
    }

    @include media($tablet) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__links {
    text-align: center;
    width: 100%;
    padding-bottom: 25px;

    & .icon {
      font-size: rem(20);
      margin-right: 10px;
    }
  }

  &__goals {
    padding: 10px;
    text-align: center;
    justify-items: center;
    padding: 0 10px 0 10px;

    @include media($tablet) {
      width: 60%;
      text-align: left;
      padding: 0 25px 0 25px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &__navigation {
    font-size: rem(25);
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
    align-items: center;

    & .icon {
      font-size: rem(30);
      margin: 0 20px 0 20px;
    }
  }

  &__image {
    width: 100%;
    display: flex;
    justify-content: center;
    &__wrapper {
      width: 325px;

      @include media($tablet) {
        width: 600px;
      }

      & img {
        width: 100%;
      }
    }
  }

  &__mockup {
    width: 400;

    @include media($tablet) {
      width: 600px;
    }

    @include media($desktop) {
      width: 800px;
    }

    & img {
      width: 100%;
    }
  }
}
