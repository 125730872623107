//breakpoints
$tablet: 768px;
$desktop: 1024px;

//colors
$color-dark: black;
$color-light: white;
$color-medium: #363636;

//fonts
$font-main: 'Mirage Bold', serif;
$font-secondary: 'Mirage Regular', serif;
$font-light: 'Mirage Thin', serif;
$font-titles: 'Mirage Black', serif;
$font-projects: 'Roboto', sans-serif;
