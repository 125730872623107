.main__projects {
  margin: 20px 0 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  position: relative;
  color: $color-light;

  @include media($tablet) {
    align-items: flex-start;
  }

  &__text {
    font-family: $font-secondary;
    color: $color-light;
    text-align: center;
    font-size: rem(18);
    margin: 15px 20px 0 20px;

    & a {
      text-decoration-line: none !important ;
      text-decoration-style: none;
    }
  }

  &__img {
    width: 400px;
    top: 50%;
    position: absolute;
    z-index: 999;

    & img {
      width: 100%;
    }
  }

  &__item {
    max-width: 500px;
    margin: 0 10px 15px 10px;
    text-align: center;
    font-size: rem(28);
    font-family: $font-main;
    line-height: 1.3em;

    &:hover {
      @include link-hover;
    }

    @include media($tablet) {
      font-size: rem(50);
      text-align: left;
    }
  }
}

.show-image {
  display: none;
  @include media($tablet) {
    display: inline;
    width: 550px;
    top: 30%;
    left: 50%;
    position: absolute;
    z-index: -1;

    & img {
      width: 100%;
    }
  }
}
